import React from "react";
import {
  workImgOne,
  workImgTwo,
  workImgThree,
  workImgFour,
  workImgSix,
  workImgEight,
} from "../../assets";
import Title from "../home/Title";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <div>
      <Title title="Recent" subTitle="Projects" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10">
        <div className="px-6">
         <a 
         href="https://programing-point.com/">
          <ProjectsCard
             title= "IT Institute"
            category="Wordpress Project"
            image={workImgThree}
          />
          </a>
          <a
          href="http://evolutiontherapycenter.com/"
          > 
          <ProjectsCard
            title="Course Sell Website"
            category="WordPress Project"
            image={workImgOne}
          />
          </a>
          <a
          href="https://globalonlineacademy.org/"
          >
          <ProjectsCard
            title="Online Education"
            category="WordPress Project"
            image={workImgTwo}
          />
          </a>
        </div>
        <div className="px-6">
          <a href="https://stunning-cactus-7fb273.netlify.app/">

          
          <ProjectsCard
            title="Photographer Portfolio"
            category="ReactJS project"
            image={workImgFour}
          />
         </a>
          <a
          href="https://pedantic-ramanujan-87898c.netlify.app/"
          >
          <ProjectsCard
            title="Event Planner"
            category="ReactJS project"
            image={workImgSix}
          />
           </a>
           <a href="https://elegant-perlman-f1a97c.netlify.app/">
           <ProjectsCard
            title="Salary-Calculator"
            category="ReactJS project"
            image={workImgEight}
          />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
