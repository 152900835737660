import React from "react";
import { FaAppStoreIos } from "react-icons/fa";
import { AiTwotoneAppstore } from "react-icons/ai";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import ServicesCard from "./ServicesCard";

const MyServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <ServicesCard
        icons={<BiCodeAlt />}
        title="Web Design"
        subTitle="will help to build Modern and mobile-responsive email template and webpage design.."
      />
      <ServicesCard
        icons={<SiAntdesign />}
        title="Web Design & Development"
        subTitle="Modern and mobile-ready website that will help you reach all of your marketing."
      />
      <ServicesCard
        icons={<AiTwotoneAppstore />}
        title="WordPress Website Services"
        subTitle="School Websites, Online Courses, E-Commerce Websites All types of WordPress websites."
      />
      <ServicesCard
        icons={<FaAppStoreIos />}
        title="Graphic Design"
        subTitle="All kinds of logo design Banner design Photo editing Visiting Card design Service vendor."
      />
    </div>
  );
};

export default MyServices;
