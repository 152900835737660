import React from "react";

const AboutMe = () => {
  return (
    <div className="flex flex-col md:flex-row pb-6">
      <div className="w-full md:w-1/2 text-zinc-400 px-6 border-r-[1px] border-r-zinc-800 flex items-center">
        <div className="py-6">
          <h2 className="font-semibold mb-1">Hello! I'm MD SAIFUL ISLAM</h2>
          <p className="text-base leading-6 ">
           Web designer & Developer from Dhaka Bangladesh. 
           I have rich experience in website design and building, 
           also I am good at WordPress. I have 3 years of Experience 
           With Different companies and Customers from Different countries in the World.
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6">
        <ul className="flex flex-col gap-1">
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Age:</span>
            20
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Residence:</span>
            Bangladesh
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Freelance:</span>
            Available
          </li>
          <li className="aboutRightLi">
            <span className="aboutRightLiSpan">Address:</span>
            Demra, Dhaka
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutMe;
