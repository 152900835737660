import React from "react";
import Title from "../home/Title";
import { work1,work2, work3,work4, work5, work6,  work7,work8,work9,work10, 
  work11,work12, work13, work14,work15, work16, work17, work18,work19,work20,
  work21,work22,work23,work24,work25,work26,work27,work28, work29, work30  } from "../../assets";
import BlogCard from "./BlogCard";

const Blog = () => {
  return (
    <div>
      <Title title="Graphic" subTitle="Project" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10">
        <div className="px-6">
        <BlogCard
            image={work30}
            title="Jun 05, 2022"
            subTitle="BD SAFA ONLINE STORE"
            category="visiting card Design"
          />
        <BlogCard
            image={work26}
            title="Feb 28, 2023"
            subTitle="Saiful.pro (visiting card)"
            category="visiting card Design"
          />
          <BlogCard
            image={work27}
            title="Feb 28, 2023"
            subTitle="Saiful.pro (visiting card)"
            category="visiting card Design"
          />
          <BlogCard
            image={work28}
            title="jan 30, 2022"
            subTitle="Certificate Design"
            category="Certificate"
          />
          <BlogCard
            image={work29}
            title="jan 30, 2021"
            subTitle="Certificate Design"
            category="Certificate"
          />
          
          <BlogCard
            image={work5}
            title="July 13, 2021"
            subTitle="Ajkarshop online shop"
            category="Logo Design"
          />
          <BlogCard
            image={work6}
            title="July 19, 2021"
            subTitle="TRANS FORMERS (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work7}
            title="September 16, 2020"
            subTitle="Canada Day (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work8}
            title="September 16, 2020"
            subTitle="Mehedi Design (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work9}
            title="September 26, 2022"
            subTitle="The Daily Carind union"
            category="Logo Design"
          />
          <BlogCard
            image={work10}
            title="September 19, 2022"
            subTitle="Beauty and Business Academy"
            category="Logo Design"
          />
          <BlogCard
            image={work11}
            title="September 01, 2022"
            subTitle="The Path online"
            category="Logo Design"
          />
          <BlogCard
            image={work12}
            title="may 10, 2021"
            subTitle="Beauty and Business Academy"
            category="Logo Design"
          />
          <BlogCard
            image={work13}
            title="Jan 06, 2022"
            subTitle="Gamming Logo"
            category="Logo Design"
          />
          <BlogCard
            image={work14}
            title="Dec 20, 2021"
            subTitle="Motor cycles Shop"
            category="Logo Design"
          />
          
        </div>
        <div className="px-6">
          
          <BlogCard
            image={work16}
            title="feb 05, 2023"
            subTitle="Active IT Academy"
            category="Logo Design"
          />
          <BlogCard
            image={work17}
            title="May 25, 2022"
            subTitle="Toxsbd It Company"
            category="Logo Design"
          />
          <BlogCard
            image={work18}
            title="Dec 04, 2022"
            subTitle="Porsche info logo"
            category="Logo Design"
          />
          <BlogCard
            image={work19}
            title="Agu 06, 2020"
            subTitle="Motor cycle Hut"
            category="Logo Design"
          />
          <BlogCard
            image={work20}
            title="September 20, 2021"
            subTitle="Faysal personal logo"
            category="Logo Design"
          />
          <BlogCard
            image={work21}
            title="September 16, 2020"
            subTitle="T-shirt logo"
            category="Logo Design"
          />
          <BlogCard
            image={work22}
            title="September 10, 2020"
            subTitle="Doritos info logo "
            category="Logo Design"
          />
          <BlogCard
            image={work23}
            title="JAN 1, 2020"
            subTitle="BDSAFA online shop "
            category="Logo Design"
          />
          <BlogCard
            image={work24}
            title="MAY 16, 2022"
            subTitle="WEALTHY MIND CHOICE"
            category="Logo Design"
          />
          <BlogCard
            image={work25}
            title="September 28, 2022"
            subTitle="LAWYER ZOON"
            category="Logo Design"
          />
         <BlogCard
            image={work1}
            title="September 16, 2019"
            subTitle="Taco Bell (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work2}
            title="July 15, 2019"
            subTitle="TRANS FORMERS (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work3}
            title="July 14, 2021"
            subTitle="Henna Art (logo)"
            category="Logo Design"
          />
          <BlogCard
            image={work4}
            title="July 13, 2020"
            subTitle="Bd Safa online store"
            category="Logo Design"
          />
          <BlogCard
            image={work15}
            title="Sep 16, 2021"
            subTitle="Pitoki course sell website"
            category="Logo Design"
          />
        </div>
      </div>
    </div>
  );
};

export default Blog;
