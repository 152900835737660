import React from "react";
import ResumeTitle from "./ResumeTitle";
import { MdWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <div className="w-full grid grid-cols-9 px-6">
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Experience" icon={<MdWork />} />
        <ResumeCard
          badge=""
          title="Web Design & Developer"
          subTitle="fiverr level 2 seller."
          des="
          HTML5 (Intermediate)
          CSS,Tailwind (Intermediate)
          Bootstrap(Intermediate)
          JavaScript (Intermediate)
          ReactJs (Experienced)
          NextJs (Intermediate)          
          MongoDB (Intermediate)
          TypeScript (Basic)"
          
        />
        <ResumeCard
          badge="2021 - 2023"
          title="Graphic Designer"
          subTitle="fiverr.com"
          des="Adobe Photoshop &  
           Adobe Illustrator Expert
          (all kinds of logo design, Banner design, visiting card design , poster design  etc.)"
        />
      
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Education" icon={<GiGraduateCap />} />
        <ResumeCard
          badge="2022 - 2027"
          title="National University Bangladesh"
          subTitle="Dhaka"
          des="Department Of Accounting Govt.Murapara University Under of National University Bangladesh"
        />
        <ResumeCard
          badge="2022 - 2027"
          title="Diploma in computer science & engineering"
          subTitle="Narayanganj , Bangladesh"
          des="National institute of engineering and technology (Narayanganj campus)"
        />
       
      </div>
    </div>
  );
};

export default Education;
